<template>
  <div style="height: 100%;">
    <!--    登录-->
    <div class="login" v-show="page_login">
      <div class="login_box">
        <div class="content">
          <div class="name">
            <input type="text" placeholder="请输入身份证上的姓名" v-model="informations.username">
          </div>
          <div class="last_six_id">
            <input type="text" placeholder="请输入身份证号后四位" v-model="informations.password">
          </div>
          <div class="Prompt">
            <img src="../assets/prompt.png" alt="">
          </div>
          <div class="check_law">
            <div @click="check_law_letter"><img src="../assets/check.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <!--律师函列表-->
    <div class="law_list" v-show="page_list">
      <div class="lawyer_list_box">
        <div class="top_area">
          <div class="top_name">{{ informations.username }}</div>
          <div class="top_number">{{ "(" + informations.password + ")" }}</div>
        </div>
        <div class="bottom_area">
          <li v-for="(value, index) in array" class="bottom_list">
            <div class="first_img">
              <img src="../assets/envelopes.png" alt="">
            </div>
            <div class="law_name">{{ value.name }}</div>

            <div class="second_img" @click="watchLaw(value,index)">
              <img v-if="value.isRead == 0" src="../assets/watch.png" alt="">
              <img v-else src="../assets/watch2.png" alt="">
            </div>
          </li>
        </div>
      </div>
    </div>
    <!--律师函图片-->
    <div class="law_img" v-show="page_img">
      <div class="box_return" @click="return_list()">
        <img src="../assets/returngroup.png" alt="">
      </div>
      <div style="width: 100%; height:auto; overflow: auto;">
        <img width="100%" height="auto" id="law_images" :src=imgsrc alt="">
      </div>
      <ul class="my_ul">
        <li id="first_li" @click="handleShowRignt">
          <i v-if="showRight" class="van-badge__wrapper van-icon van-icon-arrow-left" style="font-size: 0.16rem;"></i>
          <i v-else class="van-badge__wrapper van-icon van-icon-arrow" style="font-size: 0.16rem;"></i>
          <span>{{ showRight == false ? "隐藏" : "显示" }}</span>
        </li>
        <li :class="{'two_li':showRight==false}">
          <i class="van-badge__wrapper van-icon van-icon-comment-o" style="font-size: 0.333rem;"></i>
          <div>
            <span @click="handleShowPopup">
                  我要留言
          </span>
          </div>
        </li>
      </ul>

      <van-popup id="va_pop" v-model="showPopup" position="bottom" :style="{ height: '70%' }" closeable>
        <!--        <van-tabs v-model="tapActive" id="tap_zero" style="height: 100%"  sticky offset-top="9.733rem" swipeable>-->
        <!--        <van-tabs v-model="tapActive" id="tap_zero" style="height: 100%" swipeable>-->
        <van-tabs v-model="tapActive" swipeable style="height: 100%;overflow: hidden">
          <van-tab title="在线留言">
            <div class="msg_form">
              <div class="msg_form_item">
                <div v-for="(v,k) in msg_box" class="radio" :class="{'checked':msg_box[k].isChecked==true}"
                     @click.prevent="handleCheck(v,k)">
                  <label :for=v.id>{{ v.content }}</label>
                  <input type="radio" :id=v.id :value=v.content>
                </div>
              </div>
              <div class="textarea" :class="{'textarea_hidden':textareaShow==true}">
                <textarea v-model="textarea_content"
                          style=" width: 96%;height: 1.6rem;left: 1%;position: absolute;top: 0.1rem;resize: none;"
                          rows="3" placeholder="请输入其他问题描述..."></textarea></div>
              <div class="msg_form_item button">
                <button type="button" @click="sure_post(4)">确认提交</button>

              </div>
            </div>
          </van-tab>


          <van-tab title="回复内容">
            <div class="replay_content">
              <dl>
                <dt v-for="(v,k) in info_array">
                  <div class="content_span">
                    <div style=" width:16%;display:flex; justify-content: space-around;align-items: center">
                      <div style="width: 10px;height: 10px;background-color: #04c3ff;border-radius: 5px;"></div>
                      <div style="display: block">留言</div>
                    </div>
                    <div style="display: block">{{ v.created_at }}</div>
                  </div>
                  <div style="margin-left: 0.65rem">{{ v.info }}</div>
                </dt>
              </dl>
            </div>
          </van-tab>
        </van-tabs>
      </van-popup>
    </div>

  </div>
</template>

<script>
import {Dialog} from "vant";
import {Toast} from "vant";
import throttle from "@/utils/throttle";

export default {
  name: "Lawer",
  props: {
    msg: String
  },
  data() {
    return {
      tempKey: "",
      info_array: [],
      post_info: "",
      textarea_content: "",
      textareaShow: true,
      msg_box: [{id: 1, content: "我会尽快还款", isChecked: false},
        {id: 2, content: "申请协商还款", isChecked: false},
        {id: 3, content: "已还款请核实", isChecked: false},
        {id: 4, content: "对金额有异议", isChecked: false},
        {id: 5, content: "其他问题", isChecked: false},
        {id: 6, content: "联系我们", isChecked: false}
      ],
      showRight: "true",
      tapActive: 0,
      showPopup: false,
      //控制登录页面显示与否
      page_login: true,
      //控制list页面显示与否
      page_list: false,
      //控制律师函图片展示
      page_img: false,
      //姓名与身份证号后4位
      informations: {
        username: "",
        password: "",
        uid: ""
      },
      //存储列表的数组
      array: [],
      //存储律师函图片的src
      imgsrc: ""
    };
  },
  computed: {
    height() {
      return window.screen.height
    }
  },
  watch: {
    tapActive: {
      handler(a, b) {
        let URL = this.isProduction();
        let that = this;
        this.$axios.post(URL + "?s=api/leaveList", {
          id: this.tempKey.id
        })
            .then(function (response) {
              if (response.data.code !== 0) {
                alert(response.data.message);
                return;
              }
              that.$data.info_array = response.data.data;
            })
            .catch(function (error) {
              //Toast.fail('留言失败');
              alert(error);
            });
      }
    }
  },
  mounted() {
    window.onresize = function () {
      if (
          //@ts-ignore
          document.activeElement.tagName == "INPUT" ||
          //@ts-ignore
          document.activeElement.tagName == "TEXTAREA"
      ) {
        window.setTimeout(function () {
          //@ts-ignore
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    }
  },
  methods: {
    sure_post(k) {
      if (this.$data.msg_box[5].isChecked == true) {
        //联系我们
        return;
      }
      if (this.$data.msg_box[4].isChecked) {
        if (this.$data.textarea_content.trim() == '') {
          Toast.fail('请输入内容');
          return;
        } else {
          this.$data.post_info = this.$data.textarea_content;
        }
      } else {
        this.$data.msg_box.map((v, k) => {
          if (this.$data.msg_box[k].isChecked == true) {
            this.$data.post_info = this.$data.msg_box[k].content;
          }
        });
      }
      let URL = this.isProduction();
      let that = this;
      console.log(this.tempKey.id)

      this.$axios.post(URL + "?s=api/addLeave", {
        id: this.tempKey.id,
        info: this.$data.post_info
      })
          .then(function (response) {
            if (response.data.code !== 0) {
              alert(response.data.message);
              return;
            }
            Toast.success("留言成功");
            that.$data.tapActive = 1;
            that.$data.post_info = "";
            that.$data.textarea_content = "";
          })
          .catch(function (error) {
            Toast.fail("留言失败");
            //alert(error)
          });
    },

    //是否选中
    handleCheck(value, key) {

      //其他改为false
      this.$data.msg_box.map((v, k) => {
        if (key !== k) {
          this.$data.msg_box[k].isChecked = false;
        }
      });
      this.$forceUpdate();
      //点击的改为 ！
      this.$data.msg_box[key].isChecked = !this.$data.msg_box[key].isChecked;
      this.$forceUpdate();

      //textarea的显示与隐藏
      if (key == 4) {
        this.$data.textareaShow = !this.$data.textareaShow;
      } else {
        this.$data.textareaShow = true;
      }
      //zaizhe
      //textarea的显示与隐藏
      if (key == 5) {
        Dialog({message: "<span>请点击</span><a href=\"tel:028-86714915\">028-86714915</a>"});
      }
    }
    ,
    handleShowRignt() {
      this.$data.showRight = !this.$data.showRight;
    },
    handleShowPopup() {
      this.showPopup = true;
      //   document.getElementById('va_pop').style.height = document.documentElement.clientHeight / 2 +"px";
    },
    isProduction() {
      let URL = "";
      // 判断开发环境（一般用于本地代理）
      if (process.env.NODE_ENV === "development") { // 开发环境
        URL = "/api";
      } else {                                      // 生产环境
        URL = "index.php";
      }
      return URL;
    },
    //返回的方法
    return_list() {
      this.saveList();
      this.$data.page_list = true;//隐藏列表
      this.$data.page_img = false;//展示图片
    },
    //将数组存下来
    saveArray(response) {
      this.$data.array = response.data.data;
    },
    //将列表存储下来
    saveList(response) {
      // this.$data.informations.uid = response.data.data.uid;
      this.$data.page_list = true;
      this.$data.page_login = false;
      var that = this;
      //发送请求 获取list
      let URL = this.isProduction();
      this.$axios.post(URL + "?s=api/lawyerList", {
        uid: this.$data.informations.uid
      })
          .then(function (response) {
            //失败return掉
            if (response.data.code !== 0) {
              alert(response.data.message);
              return;
            }
            that.saveArray(response);
          })
          .catch(function (error) {
            alert(error);
          });
    },

    //查收律师函点击事件
    check_law_letter: throttle(function () { //做节流处理
      var that = this;
      //发送请求
      let URL = this.isProduction();
      this.$axios.post(URL + "?s=api/login", {
        name: this.$data.informations.username,
        card: this.$data.informations.password
      })
          .then(function (response) {
            if (response.data.code !== 0) {
              alert(response.data.message);
              return;
            }
            that.$data.informations.uid = response.data.data.uid;
            that.saveList(response);
          })
          .catch(function (error) {
            alert(error);
          });

    }, 1000),
    //点击查看按钮时，向服务器发送信息
    watchLaw: throttle(function (value, index) {//做节流处理
      //发送请求
      this.tempKey = value
      console.log(this.tempKey.id)

      let that = this;
      let URL = this.isProduction();
      this.$axios.post(URL + "?s=api/readLawyer", {
        id: value.id
      })
          .then(function (response) {
            if (response.data.code !== 0) {
              return;
            }
            that.$data.imgsrc = value.img;
          })
          .catch(function (error) {
            alert(error);
          });
      this.$data.page_list = false;
      this.$data.page_img = true;
    }, 1000)
  }
};
</script>
<style>
.van-tabs__content, .van-tab__pane {
  height: 100% !important;
}

.van-popup {
  position: absolute;
}

.van-tabs--line .van-tabs__wrap {
  height: 10%;
}

/*.van-popup--bottom{*/
/*  top: 50%;*/
/*}*/

</style>
<style scoped lang="scss">

//login页的样式
.login {
  overflow: hidden;
  width: 100%;
  height: 100%;

  .login_box {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("../assets/login_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;

    .content {
      width: 8.053rem;
      height: 6.72rem;
      position: absolute;
      top: 6.2rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .name {
    margin: 1.12rem auto 0;
    width: 7.0133rem;
    height: 1.0133rem;

    input {
      padding-left: .44rem;
      outline: none;
      border: transparent;
      border-radius: .52rem;
      height: 100%;
      width: 6.5733rem;
      background-image: url("../assets/rounded.png");
      background-size: 100%;
      background-repeat: no-repeat;

      &::placeholder {
        color: rgb(144, 172, 202);
      }
    }
  }

  .last_six_id {
    margin: .52rem auto 0;
    width: 7.0133rem;
    height: 1.0133rem;

    input {
      padding-left: .44rem;
      outline: none;
      border: transparent;
      border-radius: .52rem;
      height: 100%;
      width: 6.5733rem;
      background-image: url("../assets/rounded.png");
      background-size: 100%;
      background-repeat: no-repeat;

      &::placeholder {
        color: rgb(144, 172, 202);
      }
    }
  }

  .Prompt {
    margin: .16rem auto 0;
    padding-right: 3.2rem;
    width: 2.88rem;
    height: .2267rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .check_law {
    margin: .8rem auto;
    width: 7.0133rem;
    height: 1.0133rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

//list页面的样式
.law_list {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .lawyer_list_box {
    width: 100%;
    height: 100%;
    background-image: url("../assets/lawyerlist_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;

    .top_area {
      width: 2.0rem;
      height: 1.333rem;
      position: absolute;
      color: rgb(254, 254, 254);
      font-weight: bold;
      top: 1.333rem;
      left: 50%;
      transform: translate(-50%,0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      .top_name {
        width: 2rem;
        font-size: .56rem;
        text-align: center;
      }
      .top_number {
        text-align: center;
        width: 2rem;
        font-size: .4267rem;
      }

    }
    .bottom_area {
      position: absolute;
      top: 5.467rem;
      height: calc(100% - 5.4534rem);
      width: 100%;
      overflow: auto;
      .bottom_list {
        width: 7.333rem;
        height: 1.1733rem;
        margin: 0.40rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(233, 238, 249);
        //border-radius: 0.15rem;

        .law_name {
          flex: 1;
          margin-left: 0.48rem;
          font-size: .44rem;
          color: rgb(41, 95, 153);
        }
        .first_img {
          width: .48rem;
          height: .3733rem;
          margin-left: .28rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .second_img {
          width: 1.4933rem;
          height: .56rem;
          margin-right: .48rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

    }
  }

}

.law_img {
  height: 100%;
  font-weight: 600;

  .van-tabs__nav--line {
    box-sizing: content-box;
    height: 100%;
    padding-bottom: 0.293rem;
    padding-top: 0.293rem;
  }


  .replay_content {
    height: 80%;
    width: 93%;
    padding-left: 0.133rem;
    overflow-y: auto;

    dl {
      height: 100%;
      overflow: auto;

      dt {
        width: 95%;
        padding-right: 0;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        border-bottom: 1px dotted black;
        font-size: 0.25rem;
        border-bottom: 1px dotted black;

        .content_span {
          display: flex;
          justify-content: space-between;
          align-content: center;
          padding: 0.027rem;
        }
      }
    }
  }

  .box_return {
    position: fixed;
    height: 1.88rem;
    width: .6933rem;
    top: 3.12rem;

    img {
      width: 100%;
      height: 100%;
    }

  }

  #law_images {
    width: 100%;
    height: 100%;
  }

  ul {
    width: 1.5rem;
    background: #555;
    color: #fff;
    border-top-left-radius: 0.107rem;
    border-bottom-left-radius: 0.107rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0rem;
    top: 50%;
    z-index: 1999;

    #first_li {
      height: 0.8rem;
      text-align: center;
      cursor: pointer;
      font-size: 0.133rem;
      border-top: 0.013rem solid #878787;
      padding-top: 0.407rem;
    }

    .two_li {
      display: none;
    }

    li {
      height: 1.5rem;
      text-align: center;
      cursor: pointer;
      font-size: 0.133rem;
      border-top: 0.013rem solid #878787;
      padding-top: 0.407rem;

      .van-icon {
        position: relative;
        display: inline-block;
        font: 0.187rem/1 vant-icon;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }

      span {
        display: inline-block;
      }
    }
  }


  .msg_form {
    margin: 0.267rem auto;
    width: 90%;
    text-align: left;
    color: #333;
    background: #f2f2f2;

    .textarea_hidden {
      display: none;
    }

    .textarea {
      height: 1.667rem;
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      padding: 0.067rem;
      position: relative;
    }

    .button {
      margin: 0.12rem 0;
      padding: 0.067rem;

      button {
        width: 100%;
        height: 50px;
        color: #fff;
        background: #199ed8;
        border: none;
        border-radius: 0.067rem;
      }
    }

    .msg_form_item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .checked {
        border-radius: 0.127rem;
        border: 0.053rem solid #cc0000;

        &:after {
          display: block;
          position: absolute;
          right: 1px;
          bottom: 0;
          content: " ";
          width: 0.267rem;
          height: 0.267rem;
          //background: url(/assets/checked.87fa6a0e.svg) 8px 8px no-repeat;
          background-size: 60%;
        }
      }
    }

    .van-tabs__content {
      height: 100%;
    }

    .radio {
      flex: 40%;
      text-align: center;
      margin: 0.067rem;
      background: #fff;
      border-radius: 5px;
      border: 0.013rem solid #ccc;


      label {
        display: inline-block;
        width: 100%;
        height: 50px;
        line-height: 50px;
      }

      input[type=radio] {
        width: 0;
        visibility: hidden;
      }
    }
  }
}


</style>
