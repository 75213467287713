<template>
  <div id="app">
<!--    <Lawer/>-->
    <LawerNew/>
  </div>
</template>

<script>
// import Lawer from './components/Lawer.vue'
import LawerNew from './components/LawerNew.vue'
export default {
  name: 'App',
  components: {
    LawerNew
  },
}
</script>

<style>
  *{
    margin: 0;
    padding:0;
  }
  html,body,#app{
    height: 100%;
  }
</style>
